<template>
  <div class="contact">
    <page-header
      :title="$t('contact.page-title')"
      :has-back="true"
      @back="goBack"
    />
    <div class="md-layout md-alignment-center-center">
      <md-card
        class="contact__card md-layout-item md-size-80 md-layout md-alignment-center-center"
      >
        <md-card-content class="md-layout-item md-size-50 md-small-size-100">
          <h3 v-if="isMEEP" class="contact__card-title">
            <p>{{ $t("contact.title1") }}</p>
            <p>{{ $t("contact.title2") }}</p>
          </h3>

          <i18n
            path="contact.sub-title"
            tag="p"
            class="contact__card-sub-title"
          >
            <template #faq>
              <router-link to="/dashboard/faq/">
                {{ $t("contact.faq") }}
              </router-link>
            </template>
          </i18n>

          <form @submit.prevent="onSubmit">
            <div class="form-row">
              <div class="meep-input">
                <md-field :class="{ 'md-invalid': errors.has('lastName') }">
                  <label>{{ $t("form.lastName") }} *</label>
                  <md-input
                    v-model="form.lastName"
                    v-validate="'required'"
                    name="lastName"
                  >
                  </md-input>
                  <span v-show="errors.has('lastName')" class="md-error">
                    {{ errors.first("lastName") }}
                  </span>
                </md-field>
              </div>

              <div class="meep-input">
                <md-field :class="{ 'md-invalid': errors.has('telephone') }">
                  <label>{{ $t("form.telephone") }}</label>
                  <md-input v-model="form.telephone" name="telephone">
                  </md-input>
                </md-field>
              </div>
            </div>

            <div class="form-row">
              <div class="meep-input">
                <md-field :class="{ 'md-invalid': errors.has('firstName') }">
                  <label>{{ $t("form.firstName") }} *</label>
                  <md-input
                    v-model="form.firstName"
                    v-validate="'required'"
                    name="firstName"
                  >
                  </md-input>
                  <span v-show="errors.has('firstName')" class="md-error">
                    {{ errors.first("firstName") }}
                  </span>
                </md-field>
              </div>

              <div class="meep-input">
                <md-field :class="{ 'md-invalid': errors.has('email') }">
                  <label>{{ $t("form.email") }} *</label>
                  <md-input
                    v-model="form.email"
                    v-validate="'required|email'"
                    name="email"
                    type="email"
                  >
                  </md-input>
                  <span v-show="errors.has('email')" class="md-error">
                    {{ errors.first("email") }}
                  </span>
                </md-field>
              </div>
            </div>

            <div class="form-row">
              <div class="meep-input">
                <md-field>
                  <label>{{ $t("form.city") }}</label>
                  <md-input v-model="form.city" name="city"> </md-input>
                </md-field>
              </div>

              <div class="meep-input">
                <md-field>
                  <label>{{ $t("form.postalCode") }}</label>
                  <md-input v-model="form.postalCode" name="postalCode">
                  </md-input>
                </md-field>
              </div>
            </div>

            <div class="form-row">
              <div class="meep-input">
                <md-field v-if="!isMEEP">
                  <label>{{ $t("form.entreprise") }}</label>
                  <md-input v-model="form.entreprise" name="entreprise">
                  </md-input>
                </md-field>
                <md-field v-else>
                  <label>{{ $t("form.cabinet") }}</label>
                  <md-input v-model="form.cabinet" name="cabinet"> </md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter unset-margin">
              <md-field v-if="!isMEEP" class="">
                <label>{{ $t("contact.my-question") }}</label>
                <md-textarea
                  v-model="form.description"
                  v-validate="'required'"
                  name="description"
                ></md-textarea>
              </md-field>
              <md-field v-else>
                <label>{{ $t("contact.my-need") }}</label>
                <md-textarea
                  v-model="form.description"
                  v-validate="'required'"
                  name="description"
                ></md-textarea>
              </md-field>
              <div class="md-layout-item">
                <p
                  v-show="errors.has('description')"
                  class="md-error custom-error rm-margin-top"
                >
                  {{ errors.first("description") }}
                </p>
              </div>
            </div>
            <div class="md-layout md-alignment-center-center">
              <vue-recaptcha
                :sitekey="sitekeyRecaptcha"
                :load-recaptcha-script="true"
                @verify="verifyRecaptcha"
                @error="errorRecaptcha"
                @expired="expiredRecaptcha"
              >
              </vue-recaptcha>
            </div>
            <div class="md-layout md-alignment-center-center">
              <md-button
                type="submit"
                class="contact__submit md-primary md-raised"
                :disabled="!isCaptchaPassed"
              >
                {{ $t("send") }}
              </md-button>
            </div>
          </form>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import mailModel from "../model/mail";
import VueRecaptcha from "vue-recaptcha";
import { mapGetters } from "vuex";
import { sitekeyRecaptcha } from "../config";

export default {
  name: "Contact",

  components: { PageHeader, VueRecaptcha },

  mixins: [HasBackMixin],

  data: () => ({
    form: {
      email: "",
      firstName: "",
      lastName: "",
      postalCode: "",
      telephone: "",
      city: "",
      cabinet: "",
      entreprise: "",
      description: "",
    },
    isCaptchaPassed: false,
    sitekeyRecaptcha: "",
  }),
  computed: {
    ...mapGetters(["isMEEP"]),
  },
  created() {
    this.sitekeyRecaptcha = sitekeyRecaptcha;
  },
  mounted: function () {},

  methods: {
    async onSubmit() {
      const result = await this.$validator.validateAll();
      console.log(result);
      if (!result) {
        this.$toasted.global.AppError({
          message: "Vous devez remplir les champs manquants",
        });
        return;
      }
      try {
        await mailModel.sendContactInfo(this.form);
        this.$toasted.global.AppSucces({
          message: "L'email a été envoyé",
        });
        this.$router.push("/dashboard/");
      } catch (error) {
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    getMailBody() {
      return `${this.form.description}

Mes informations:
Nom: ${this.form.lastName}
Prénom: ${this.form.firstName}
Numéro de téléphone: ${this.form.telephone}
${
  (this.isMEEP && `Cabinet: ${this.form.cabinet}`) ||
  `Entreprise: ${this.form.entreprise}`
}
Ville: ${this.form.city}
Code postal: ${this.form.postalCode}
Question: ${this.form.description}
      `;
    },
    verifyRecaptcha(/*res res use capture respone from google recaptcha if someone want use respone*/) {
      this.isCaptchaPassed = true;
    },
    errorRecaptcha() {
      this.isCaptchaPassed = false;
    },
    expiredRecaptcha() {
      this.isCaptchaPassed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  padding-bottom: 3rem;

  &__card-title {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    p {
      margin: 0 auto;
    }
  }

  &__card-sub-title {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__submit {
    width: 150px;
  }

  .custom-error {
    color: #ff1744;
  }
  .unset-margin {
    margin: unset !important;
  }

  .rm-margin-top {
    margin-top: unset !important;
  }

  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .meep-input {
      flex: 0 1 48%;
    }
  }

  @media (max-width: 559px) {
    .form-row {
      flex-wrap: wrap;

      .meep-input {
        flex: 1 1 100%;
      }
    }
  }
}
</style>
