import fetchService from "../services/fetch";
import store from "../store";

function sendContactInfo(info) {
  return fetchService("api/mail/contact-email", {
    method: "post",
    headers: {
      Authorization: store.state.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(info),
  });
}

export default {
  sendContactInfo,
};
